<template>
  <div class="racks">
    <h2>Hvor mange stel?</h2>

    <div class="racks__incrementer">
      A-stel:
      <div class="buttons double">
        <button class="button" tabindex="1" @click="countA(-1)">
          <i class="fas fa-minus"></i>
        </button>
        <input class="input racks__count" type="number" min="0" v-model="counterA" placeholder="0" />
        <button class="button" tabindex="1" @click="countA(1)">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      L-stel:
      <div class="buttons double">
        <button class="button" tabindex="1" @click="countL(-1)">
          <i class="fas fa-minus"></i>
        </button>
        <input class="input racks__count" type="number" min="0" v-model="counterL" placeholder="0" />
        <button class="button" tabindex="1" @click="countL(1)">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div v-if="racks.length" class="racks__scanned">(Heraf {{ racks.length }} stel med kode)</div>
      <div class="racks__total" :class="{ invalid: !valid }">
        <span>{{ valid === false ? "Max antal cifre er 3" : "Stel i alt" }}</span>
        <span class="racks__total__count">{{ totalRacks }}</span>
      </div>
    </div>

    <div class="buttons single">
      <button class="button" tabindex="1" @click="next"><i class="far fa-arrow-alt-circle-right"></i>Videre til adresse</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "RackDetails",
  props: {
    msg: String,
  },
  data() {
    return {
      racks: [],
      counterA: 0,
      counterL: 0,
      valid: true,
    };
  },
  watch: {
    counterA() {
      if (this.counterA !== null) {
        if (this.counterA.toString().length > 3) {
          this.throw();
          return (this.counterA = this.counterA.toString().slice(0, 3));
        }
      }
    },
    counterL() {
      if (this.counterL !== null) {
        if (this.counterL.toString().length > 3) {
          this.throw();
          return (this.counterL = this.counterL.toString().slice(0, 3));
        }
      }
    },
  },
  computed: {
    totalRacks() {
      return Number(isNaN(this.counterA) ? 0 : this.counterA) + Number(isNaN(this.counterL) ? 0 : this.counterL) + this.racks.length;
    },
  },
  methods: {
    countA(num) {
      if (num > 0) {
        if (this.counterA >= 999) {
          return this.throw();
        }
        return this.counterA++;
      }
      if (num < 0 && this.counterA > 0) {
        return this.counterA--;
      }
    },
    countL(num) {
      if (num > 0) {
        if (this.counterL >= 999) {
          return this.throw();
        }
        return this.counterL++;
      }
      if (num < 0 && this.counterL > 0) {
        return this.counterL--;
      }
    },
    throw() {
      this.valid = false;
      setTimeout(() => {
        this.valid = true;
      }, 2000);
    },
    storeRacks() {
      this.$store.state.extraA = parseInt(this.counterA) >= 0 ? this.counterA : 0;
      this.$store.state.extraL = parseInt(this.counterL) >= 0 ? this.counterL : 0;
    },
    back() {
      this.storeRacks();
      this.$router.push("/register");
    },
    next() {
      this.storeRacks();
      this.$router.push("/location");
    },
  },
  mounted() {
    var vm = this;
    if (vm.$store.state.codes === undefined) {
      vm.$router.push("/");
    } else {
      vm.counterA = this.$store.state.extraA ? this.$store.state.extraA : null;
      vm.counterL = this.$store.state.extraL ? this.$store.state.extraL : null;

      vm.racks = this.$store.state.codes ? this.$store.state.codes : [];
      vm.total = vm.racks.length + vm.counterA + vm.counterL;
    }
  },
};
</script>
<style scoped lang="scss">
h2 {
  text-align: center;
}
h2 + .buttons {
  padding-top: 0;
}
h3 {
  margin-top: 2rem;
}
.racks {
  &__scanned {
    text-align: center;
    font-style: italic;
  }
}
.invalid {
  span:first-child {
    color: red;
  }
}
</style>
