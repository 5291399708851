<template>
  <div class="container details">
    <RackDetails msg="Hvor mange?" />
  </div>
</template>

<script>
import RackDetails from "@/components/RackDetails.vue";

export default {
  name: "Register",
  components: {
    RackDetails,
  },
};
</script>
